import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaCaretDown } from "react-icons/fa";
import logo from "../../assets/logo.png";
import { IoIosLogOut } from "react-icons/io";
import axios from "axios";

const TOPBAR_BG_TRANSPARENT_PAGES = ["/home", "/dashboard"];

const Topbar = () => {
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleMenuItemClick = (path) => {
    setDropdownOpen(false);
    navigate(path);
  };

  const handleLogout = async() => {
    try{
      const config = {
        method:"delete",
        url:`${process.env.REACT_APP_BASE_URL}:9999/admin-service/auth/admin/logout`,
        headers: {
          Authorization: "Bearer "+sessionStorage.getItem('adminAuthToken')
        }
      }
      const res = await axios.request(config)
      localStorage.clear();
      navigate("/admin/login");
    }
    catch(err){
      console.log(err)
    }
  }

  return (
    <div className="border bg-white relative flex justify-between items-center px-[18px] py-3 w-full h-[80px] z-10">
      <div className="cursor-pointer" onClick={() => navigate("/")}>
        <img className="sm:pl-0 pl-12 max-w-[120px] object-cover" src={logo} alt="Logo" />
      </div>
      <div
        className={`flex gap-4 h-full items-center ${
          TOPBAR_BG_TRANSPARENT_PAGES.includes(pathname)
            ? "text-main_bg"
            : "text-text_high"
        }`}
      >
        <div
          className="relative flex gap-3 items-center cursor-pointer"
          onClick={toggleDropdown}
        >
          <div
            className={
              TOPBAR_BG_TRANSPARENT_PAGES.includes(pathname)
                ? "text-[#101828]"
                : ""
            }
          >
            <div className="flex gap-1">
              <h6 className="text-sm">Admin</h6>
              <FaCaretDown />
            </div>
          </div>
          {dropdownOpen && (
            <div className="absolute right-0 py-2 flex flex-col items-center justify-center mt-[100px] w-52 bg-white border border-gray-200 rounded shadow-md z-20">
              <div
                className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center gap-2 w-full"
                onClick={() => handleLogout()}
              >
                <IoIosLogOut />
                Logout
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Topbar;
