import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Login from "./admin/pages/Login/Login";
import Layout from "./admin/pages/Layout/Layout";
import Bookings from "./admin/pages/Bookings/Bookings";
import BookingDetails from "./admin/pages/BookingDetails/BookingDetails";
import SignupAdmin from "./admin/pages/Signup/Signup";
import ForgetPassword from "./admin/pages/ForgetPassword/ForgetPassword";
import NewPassword from "./admin/pages/NewPassword/NewPassword";
import CareBookings from "./admin/pages/CareBookings/CareBookings";
import ProtectedRoute from './ProtectedRoute';  // Import the ProtectedRoute component
import CareBookingDetails from './admin/pages/CareBookingDetails/CareBookingDetails';
import IndividualBooking from './admin/pages/IndividualBooking/IndividualBooking';
const App = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const adminAuthToken = localStorage.getItem('adminAuthToken');
    if (window.location.pathname === '/' || window.location.pathname === '/admin') {
      if (adminAuthToken) {
        navigate('/admin/repair/bookings');
      } else {
        navigate('/admin/login');
      }
    }
  }, [navigate]);

  return (
    <Routes>
      {/* Admin Routes */}
      <Route
        path="/admin/login"
        element={
          <>
            <Helmet>
              <title>Gadgets Reborn - Admin</title>
            </Helmet>
            <Login />
          </>
        }
      />
      <Route
        path="/admin/repair/sign-up"
        element={
          <>
            <Helmet>
              <title>Gadgets Reborn - Admin</title>
            </Helmet>
            <SignupAdmin />
          </>
        }
      />
      <Route
        path="/admin/forget-password"
        element={
          <>
            <Helmet>
              <title>Gadgets Reborn - Admin</title>
            </Helmet>
            <ForgetPassword />
          </>
        }
      />
      <Route
        path="/admin/new-password"
        element={
          <>
            <Helmet>
              <title>Gadgets Reborn - Admin</title>
            </Helmet>
            <NewPassword />
          </>
        }
      />

      <Route
        path="/admin/repair/bookings"
        element={
          <ProtectedRoute>
             <title>Gadgets Reborn - Admin</title>
            <Layout children={<Bookings />} />
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/care-bookings"
        element={
          <ProtectedRoute>
            <Layout children={<CareBookings />} />
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/repair/booking-details"
        element={
          <ProtectedRoute>
            <Layout children={<BookingDetails />} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/care/booking-details"
        element={
          <ProtectedRoute>
            <Layout children={<CareBookingDetails />} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/individual-booking-details"
        element={
          <ProtectedRoute>
            <Layout children={<IndividualBooking />} />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

const RootApp = () => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

export default RootApp;
