import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import assest from "../../assets/login.png";
import logo from "../../assets/logo.png";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}:9999/admin-service/auth/admin/login`,
      headers: { "content-type": "application/json" },
      data: { userName: email, password: password },
    };

    try {
      const { data } = await axios.request(options);
      if (data.status === 200) {
        console.log(data);
        const accessToken = data.data.accessToken;
        sessionStorage.setItem("adminAuthToken", accessToken);
        navigate("/admin/repair/bookings");
      } else {
        setError(data.msg || "Login failed");
      }
    } catch (error) {
      console.error(error);
      setError("An error occurred during login");
    }
  };

  return (
    <div className="min-h-screen relative">
      <div className="absolute py-[42px] px-[36px]">
        <img className="max-w-[140px] object-cover" src={logo} alt="" />
      </div>
      <div className="grid sm:grid-cols-2 sm:mx-0 mx-[24px]">
        <div className="col-span-1  items-center justify-center sm:flex hidden bg-[#D0D5DD]">
          <img className="max-w-[450px]" src={assest} alt="" />
        </div>
        <div className="col-span-1">
          <div className="flex items-center justify-center min-h-screen bg-[#ffffff]">
            <div className="w-full max-w-md py-6 px-8 bg-white rounded-[12px] shadow-md border-[1.5px] border-[#FBC903]">
              <h2 className="mb-6 text-2xl font-bold text-start">Log in</h2>
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label className="block mb-1 text-sm font-medium">
                    Email
                  </label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full px-4 py-2 border border-gray-300 rounded-[12px] focus:outline-none focus:ring-2 focus:ring-[#FBC903]"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block mb-1 text-sm font-medium">
                    Password
                  </label>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full px-4 py-2 border border-gray-300 rounded-[12px] focus:outline-none focus:ring-2 focus:ring-[#FBC903]"
                    required
                  />
                </div>

                <button
                  type="submit"
                  className="w-full mt-6 mb-6 px-4 py-2 text-lg font-medium bg-[#FBC903] rounded-[12px] hover:bg-yellow-300 focus:outline-none focus:ring-2 focus:ring-[#FBC903]"
                >
                  Login
                </button>
              </form>
              {error && (
                <p className="mt-4 text-red-500 text-center">{error}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
