import React, { useState } from "react";
import { FaChevronDown, FaMinus, FaPhoneAlt, FaPlus } from "react-icons/fa";
import {
  MdKeyboardArrowLeft,
  MdOutlineDateRange,
  MdOutlineMail,
} from "react-icons/md";
import { ToastContainer } from "react-toastify";
import Modal from "../../components/Modal/Modal";
import { IoCloseOutline } from "react-icons/io5";
import IconMessage from "../../assets/message.svg";

const IndividualBooking = () => {
  const [form, setForm] = useState({
    amount: 0,
    description: "",
    comment: "",
  });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const booking = {
    address: {
      name: "Ankit Kumar",
      email: "john@example.com",
      mobileNo: "1234567890",
      apartment: "123",
      landMark: "Near the park",
      locality: "Sector 12",
      zipCode: "123456",
    },
    serviceUuid: "GR001AA0100240",
    modelName: "iPhone 13",
    categoryName: "Smartphone",
    brandName: "Apple",
    defectName: "Broken Screen",
    defectDesc:
      "Broken Screen and with many Scraches Broken Screen and with many Scraches Broken Screen and with many Scraches Broken Screen and with many Scraches",
    totalAmount: 1000,
    amountPaid: 500,
    createdAt: "2021-01-01",
    serviceStatus: "pending",
  };

  const [status, setStatus] = useState(booking?.serviceStatus || "");
  const [statuses, setStatuses] = useState([
    "pending",
    "in_progress",
    "diagnose_completed",
  ]); // Initialize with an empty array

  const [isOpen, setIsOpen] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleStatusChange = async (newStatus) => {
    setStatus(newStatus);
    setIsOpen(false);
    handleModalOpen();
    console.log(newStatus);
  };

  const comments = [
    {
      author: "John Doe",
      comment: "Broken Screen and with many Scraches Broken Screen ",
    },
    {
      comment: "Broken Screen and with many Scraches Broken Screen ",
    },
    {
      author: "John Doe",
      comment: "Broken Screen and with many Scraches Broken Screen ",
    },
    {
      author: "John Doe",
      comment: "Broken Screen and with many Scraches Broken Screen ",
    },
  ];
  return (
    <div>
      <Modal open={isModalOpen} onOpenChange={handleModalClose}>
        <div className="flex justify-center | relative">
          <img src={IconMessage} className="w-12 h-12 pt-2" alt="" />
          <button onClick={handleModalClose} className="absolute top-0 right-0">
            <IoCloseOutline size={24} />
          </button>
        </div>
        {status === "diagnose_completed" ? null : (
          <div className="mt-3 text-center text-lg font-semibold">
            Add comment
          </div>
        )}
        {status === "diagnose_completed" ? (
          <div className="flex flex-col gap-2 mt-5">
            <div className="flex flex-col gap-2">
              <label className="font-semibold text-lg" htmlFor="">
                Add Amount
              </label>
              <div className="flex gap-2 w-full">
                <div className="grow">
                  <input
                    type="number"
                    name="amount"
                    value={form.amount}
                    onChange={handleChange}
                    placeholder="Amount"
                    className="w-full border border-[#E4B703] rounded-md p-2 bg-white h-[57px] px-5"
                  />
                </div>
                <button
                  onClick={() => setForm({ ...form, amount: form.amount + 1 })}
                  className="bg-[#E4B703] text-black rounded-md h-[57px] aspect-square | flex items-center justify-center"
                >
                  <FaPlus className="text-white" />
                </button>
                <button
                  onClick={() =>
                    setForm({
                      ...form,
                      amount: form.amount > 0 ? form.amount - 1 : 0,
                    })
                  }
                  className="bg-[#E4B703] text-black rounded-md h-[57px] aspect-square | flex items-center justify-center"
                >
                  <FaMinus className="text-white" />
                </button>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <label className="font-semibold text-lg" htmlFor="">
                Add Description
              </label>
              <textarea
                type="text"
                name="description"
                value={form.description}
                onChange={handleChange}
                placeholder="Description"
                maxLength={100}
                className="w-full focus:outline-none border border-[#E4B703] rounded-md p-2 bg-white h-[57px] px-5"
              />
            </div>
            <div className="flex flex-col gap-2">
              <label className="font-semibold text-lg" htmlFor="">
                Add Comment
              </label>
              <textarea
                type="text"
                name="comment"
                value={form.comment}
                onChange={handleChange}
                placeholder="Comment"
                className="w-full border focus:outline-none border-[#E4B703] rounded-md p-2 bg-white h-[57px] px-5"
              />
            </div>
          </div>
        ) : (
          <textarea
            type="text"
            name="comment"
            value={form.comment}
            onChange={handleChange}
            placeholder="Comment"
            className="w-full border focus:outline-none border-[#E4B703] rounded-md p-2 bg-white h-[57px] px-5 mt-5"
          />
        )}

        <button className="mt-8 w-full py-2.5 rounded-lg font-bold text-black bg-[#E4B703]">
          Add
        </button>
      </Modal>
      <ToastContainer />
      <button
        onClick={() => window.history.back()}
        className="sm:block hidden whitespace-nowrap px-[10px] py-[5px] sm:flex items-center justify-center gap-2 bg-white border border-gray-300 rounded-md shadow-sm"
      >
        <span className="">
          <MdKeyboardArrowLeft />
        </span>
        <span className="font-bold font-plus-jakarta-sans text-[14px]">
          Go Back
        </span>
      </button>
      <div className="flex sm:flex-row flex-col gap-8 mt-4">
        <div className="grow">
          <div className="sm:block hidden">
            <div className="">
              <div className="border rounded-[24px] shadow py-4 px-6">
                <div className="flex gap-[14px]">
                  {/* <img className="object-cover" src={Profile} alt="" /> */}
                  <div>
                    <h3 className="text-[#101828] text-[18px] font-semibold">
                      {booking?.address?.name || "N/A"}
                    </h3>
                    <p className="text-[#475467] text-sm">
                      ID: {booking?.serviceUuid || "N/A"}
                    </p>
                  </div>
                </div>
                <div className="mt-4 flex gap-[42px]">
                  <div className="flex flex-col  justify-center">
                    <div className="flex gap-[6px]">
                      <MdOutlineMail size={18} color="#E4B703" />
                      <h3 className="text-sm font-bold text-[#475467]">
                        Email
                      </h3>
                    </div>
                    <p className="text-[12px] text-[#475467]">
                      {booking?.address?.email || "N/A"}
                    </p>
                  </div>
                  <div className="flex flex-col  justify-center">
                    <div className="flex gap-[6px]">
                      <FaPhoneAlt size={16} color="#E4B703" />
                      <h3 className="text-sm font-bold text-[#475467]">
                        Phone Number
                      </h3>
                    </div>
                    <p className="text-[12px] text-[#475467]">
                      {booking?.address?.mobileNo || "N/A"}
                    </p>
                  </div>
                  <div className="flex flex-col  justify-center">
                    <div className="flex gap-[6px]">
                      <MdOutlineDateRange size={18} color="#E4B703" />
                      <h3 className="text-sm font-bold text-[#475467]">Date</h3>
                    </div>
                    <p className="text-[12px] text-[#475467]">
                      {new Date(booking.createdAt).toLocaleDateString()}
                    </p>
                  </div>
                  <div className="relative inline-block text-left">
                    <button
                      type="button"
                      onClick={toggleDropdown}
                      className="inline-flex gap-[6px] items-center justify-center w-full rounded-md border border-yellow-400 shadow-sm px-4 py-2 bg-white text-sm font-medium text-yellow-600  focus:outline-none"
                    >
                      {status}
                      <FaChevronDown />
                    </button>

                    {isOpen && (
                      <div className="absolute flex items-center justify-center mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          {statuses.map((item) => (
                            <button
                              key={item}
                              onClick={() => handleStatusChange(item)}
                              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                            >
                              {item}
                            </button>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="mt-4 border-t-[2px]">
                  <div className="mt-4">
                    <h3 className="text-sm text-[#475467] font-bold">
                      Device details
                    </h3>
                    <div className="mt-[8px]">
                      <h3 className="text-[#101828] text-[16px] font-bold">
                        {booking?.modelName || "N/A"}
                      </h3>
                      <p className="text-[12px] text-[#344054]">
                        Type:
                        <span className="font-semibold">
                          {" "}
                          {booking?.categoryName || "N/A"}
                        </span>
                      </p>
                      <p className="text-[12px] text-[#344054]">
                        Brand:
                        <span className="font-semibold">
                          {" "}
                          {booking?.brandName || "N/A"}
                        </span>
                      </p>
                      <p className="text-[12px] text-[#344054]">
                        Series:
                        <span className="font-semibold">
                          {" "}
                          {booking?.modelName || "N/A"}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="border rounded-[24px] shadow py-4 px-6 mt-4">
              <div className="grid grid-cols-2 items-center justify-center">
                <div className="col-span-1 ">
                  <p className="font-bold text-sm text-[#475467]">
                    Description
                  </p>
                  <h3 className="text-[#101828] text-[16px] font-bold mt-1">
                    {booking?.defectName || "N/A"}
                  </h3>
                  <p className="text-sm text-[#475467]">
                    {booking?.defectDesc || "N/A"}
                  </p>
                </div>
              </div>
            </div>
            <div className="border rounded-[24px] shadow py-4 px-6 mt-4">
              <p className="font-bold text-sm text-[#475467]">
                Payment details
              </p>
              <div className="flex justify-between">
                <p className="text-[12px]">Total </p>
                <p className="text-[12px] font-semibold">
                  {booking?.totalAmount || "N/A"}
                </p>
              </div>
              <div className="flex justify-between">
                <p className="text-[12px]">Discount</p>

                <p className="text-[12px] font-semibold">
                  {booking?.amountPaid === null ||
                  booking?.amountPaid === 0 ||
                  booking?.amountPaid === "N/A"
                    ? "N/A"
                    : booking?.totalAmount - booking?.amountPaid}
                </p>
              </div>
              {/* <div className="flex justify-between">
                    <p className="text-[12px]">Delivery fee</p>
                    <p className="text-[12px] font-semibold">1.3 AED</p>
                    </div> */}
              <div className="flex justify-between">
                <p className="text-[12px]">Amount paid</p>
                <p className="text-[12px] font-semibold">
                  {booking?.amountPaid || "N/A"}
                </p>
              </div>
              <div className="mt-4 border-t-[2px] py-4">
                <div className="flex justify-between">
                  <p>Amount to be paid</p>
                  <h3 className=" text-[28px]">
                    {booking?.amountPaid || "N/A"}
                  </h3>
                </div>
              </div>
            </div>
            <div className="mt-6 flex justify-end">
              <button className=" w-[160px] py-2.5 rounded-lg font-bold text-black bg-[#E4B703]">
                Update
              </button>
            </div>
          </div>
          <div className="sm:hidden block">
            <div className="mt-4 bg-white p-2 rounded-md shadow border">
              <div className="flex gap-[14px] px-2">
                {/* <img className="object-contain rounded" src={Profile} alt="" /> */}
                <div>
                  <h3 className="text-[#101828] text-[18px] font-semibold">
                    {booking?.address?.name || "N/A"}
                  </h3>
                  <p className="text-[#101828] text-sm">
                    ID: {booking?.serviceUuid || "N/A"}
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-start mt-[20px] gap-1 px-2">
                <div className="flex flex-col items-center justify-center">
                  <div className="flex gap-[6px]">
                    <MdOutlineMail size={18} color="#E4B703" />
                    <p className="text-[12px] text-[#101828]">
                      {booking?.address?.email || "N/A"}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center">
                  <div className="flex gap-[6px]">
                    <FaPhoneAlt size={16} color="#E4B703" />
                    <p className="text-[12px] text-[#101828]">
                      {booking?.address?.mobileNo || "N/A"}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center">
                  <div className="flex gap-[6px]">
                    <MdOutlineDateRange size={18} color="#E4B703" />
                    <p className="text-[12px] text-[#101828]">
                      {new Date(booking.createdAt).toLocaleDateString()}
                    </p>
                  </div>
                </div>
              </div>
              <div className="relative inline-block mt-5 mx-auto w-full px-2">
                <button
                  type="button"
                  onClick={toggleDropdown}
                  className="inline-flex gap-[6px] items-center justify-center w-full rounded-md border border-yellow-400 shadow-sm px-4 py-2 bg-white text-sm font-medium text-yellow-600  focus:outline-none"
                >
                  {status}
                  <FaChevronDown />
                </button>

                {isOpen && (
                  <div className="absolute flex items-center justify-center mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      {statuses.map((item) => (
                        <button
                          key={item}
                          onClick={() => handleStatusChange(item)}
                          className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        >
                          {item}
                        </button>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              <div className="py-5 border-b-[2px] px-2">
                <div>
                  <h3 className="text-sm text-[#475467] font-bold">
                    Device details
                  </h3>
                  <div className="mt-[8px]">
                    <h3 className="text-[#101828] text-[16px] font-bold">
                      {booking?.modelName || "N/A"}
                    </h3>
                    <p className="text-[12px] text-[#344054]">
                      Type:
                      <span className="font-semibold">
                        {" "}
                        {booking?.categoryName || "N/A"}
                      </span>
                    </p>
                    <p className="text-[12px] text-[#344054]">
                      Brand:
                      <span className="font-semibold">
                        {" "}
                        {booking?.brandName || "N/A"}
                      </span>
                    </p>
                    <p className="text-[12px] text-[#344054]">
                      Series:
                      <span className="font-semibold">
                        {" "}
                        {booking?.modelName || "N/A"}
                      </span>
                    </p>
                    {/* <p className="text-[12px] text-[#344054]">
                            Date of Purchase:
                            <span className="font-semibold">
                                {" "}
                                {booking?.purchaseDuration || "N/A"}
                            </span>
                            </p> */}
                  </div>
                </div>
              </div>
              <div className="py-5 border-b-[2px] px-2">
                <div className="grid grid-cols-2 items-center justify-center">
                  <div className="col-span-1">
                    <p className="font-bold text-sm text-[#475467]">
                      Description
                    </p>
                    <h3 className="text-[#101828] text-[16px] font-bold mt-1">
                      {booking?.defectName || "N/A"}
                    </h3>
                    {/* <p className="text-[12px] text-[#101828]">
                            {booking?.planDesc || "N/A"}
                            </p> */}
                  </div>
                  {/* <div className="col-span-1 pl-4">
                        <p className="font-bold text-sm text-[#475467]">Note</p>
                        <p className="text-[12px] text-[#101828]">
                        Broken Screen and with many Scraches Broken Screen and with many
                        ScrachesBroken Screen and with many Scraches Broken Screen and
                        with many Scraches
                        </p>
                    </div> */}
                </div>
              </div>

              <div className="py-5 border-b-[2px] px-2">
                <div className="grid grid-cols-2 items-center justify-center">
                  <div className="col-span-1">
                    <p className="font-bold text-sm text-[#475467]">Address</p>
                    <p className="text-[12px] text-[#101828]  mt-1">
                      {booking?.address?.apartment},{" "}
                      {booking?.address?.landMark}, {booking?.address?.locality}
                      , {booking?.address?.zipCode}
                    </p>
                  </div>
                </div>
              </div>
              <div className="px-2 py-5">
                <p className="font-bold text-sm text-[#475467]">
                  Payment details
                </p>
                <div className="flex justify-between">
                  <p className="text-[12px]">Total </p>
                  <p className="text-[12px] font-semibold">
                    {booking?.totalAmount || "N/A"}
                  </p>
                </div>
                <div className="flex justify-between">
                  <p className="text-[12px]">Discount</p>
                  <p className="text-[12px] font-semibold">
                    {booking?.amountPaid === null ||
                    booking?.amountPaid === 0 ||
                    booking?.amountPaid === "N/A"
                      ? "N/A"
                      : booking?.totalAmount - booking?.amountPaid}
                  </p>
                </div>
                <div className="flex justify-between">
                  <p className="text-[12px]">Amount paid</p>
                  <p className="text-[12px] font-semibold">
                    {booking?.amountPaid || "N/A"}
                  </p>
                </div>
                <div className="mt-4 border-t-[2px] py-4">
                  <div className="flex justify-between">
                    <p>Amount to be paid</p>
                    <h3 className=" text-[18px]">
                      {booking?.amountPaid || "N/A"}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-l border-[#D0D5DD] | pl-8 pr-4 | flex flex-col gap-4 | max-w-[250px] min-w-[250px]">
          <h6 className="text-xs">Comments</h6>
          <div className="flex flex-col gap-6">
            {comments.map((comment, index) => (
              <div
                key={index}
                className={`flex flex-col gap-2 | pb-2 | ${
                  index !== comments.length - 1
                    ? "border-b border-[#D0D5DD]"
                    : ""
                }`}
              >
                <h6 className="text-lg font-medium">{comment.author}</h6>
                <p className="text-sm">{comment.comment}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndividualBooking;
