import React from "react";
import assest from "../../assets/login.png";
import { FaGoogle, FaApple } from "react-icons/fa";
import logo from "../../assets/logo.png";

function NewPassword() {
  return (
    <div className="min-h-screen relative">
      <div className="absolute py-[42px] px-[36px]">
        <img className="max-w-[140px] object-cover" src={logo} alt="" />
      </div>
      <div className="grid sm:grid-cols-2">
        <div className="col-span-1 items-center justify-center flex  bg-[#D0D5DD]">
          <img className="max-w-[450px]" src={assest} alt="" />
        </div>
        <div className="col-span-1">
          <div className="flex items-center justify-center min-h-screen bg-[#ffffff]">
            <div className="w-full max-w-md py-6 px-8 bg-white rounded-[12px] shadow-md border-[1.5px] border-[#FBC903]">
              <h2 className="text-2xl text-start">Create new <strong>Password</strong></h2>
              
              <p className="text-[#9E9E9E] mb-6 text-start">Please write your new password</p>
              <form>
                <div className="mb-4">
                  <label className="block mb-1 text-sm font-medium">
                    Create New Password
                  </label>
                  <input
                    type="email"
                    className="w-full px-4 py-2 border border-gray-300 rounded-[12px] focus:outline-none focus:ring-2 focus:ring-[#FBC903]"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block mb-1 text-sm font-medium">
                    Re-enter New Password
                  </label>
                  <input
                    type="password"
                    className="w-full px-4 py-2 border border-gray-300 rounded-[12px] focus:outline-none focus:ring-2 focus:ring-[#FBC903]"
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="w-full px-4 py-2 text-lg font-medium bg-[#FBC903] rounded-[12px] hover:bg-yellow-300 focus:outline-none focus:ring-2 focus:ring-[#FBC903]"
                >
                  Login
                </button>
                <div className="flex items-center justify-between mt-6">
                  <button
                    type="button"
                    className="flex items-center justify-center w-full px-4 py-2 mr-2 text-sm font-medium bg-gray-100 border border-gray-300 rounded-[12px] hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-300"
                  >
                    <FaGoogle className="mr-2" /> Google
                  </button>
                  <button
                    type="button"
                    className="flex items-center justify-center w-full px-4 py-2 ml-2 text-sm font-medium bg-gray-100 border border-gray-300 rounded-[12px] hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-300"
                  >
                    <FaApple className="mr-2" /> Apple
                  </button>
                </div>
              </form>
              <p className="mt-6 text-sm text-center">
                Don't have an account?{" "}
                <a
                  href="#"
                  className="text-[#B28F02] hover:underline font-semibold"
                >
                  Sign up
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewPassword;
