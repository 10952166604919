import { Menu } from "antd";
import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Topbar from "../../components/TopBar/TopBar";
import { FaBars, FaTimes, FaCaretDown } from "react-icons/fa";
import { MdHome, MdPersonAdd, MdPeople } from "react-icons/md";
import { BsClipboard2Minus } from "react-icons/bs";

const Layout = (props) => {
  const [selectedKey, setSelectedKey] = useState("1");
  const [collapsed, setCollapsed] = useState(false);
  const [authState, setAuthState] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const sidebarRef = useRef(null);

  const onClickMenuItem = (e) => {
    setSelectedKey(e.key);
  };

  const location = useLocation();
  const navigate = useNavigate();

  const [keyCollapseMenu, setKeyCollapseMenu] = useState({
    2: false,
  });

  const items = [
    {
      key: "bookings",
      icon: <MdPeople className="w-[20px] h-[20px]" />,
      label: "Repair Bookings",
      path: "/admin/repair/bookings",
    },
    {
      key: "careBookings",
      icon: <MdPersonAdd className="w-[18px] h-[18px]" />,
      label: "Care Bookings",
      path: "/admin/care-bookings",
    },
    // {
    //   key: "careBookings",
    //   icon: <BsClipboard2Minus className="w-[18px] h-[18px]" />,
    //   label: "Individual Bookings",
    //   path: "/admin/individual-booking-details",
    // },
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsMobileMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div>
      <div className="sticky top-0 z-[100] w-full">
        <Topbar />
        <div
          className="sm:hidden absolute top-[7px] left-2 m-4 z-[110] p-2 h-[35px] w-[35px] border text-black flex items-center justify-center cursor-pointer"
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        >
          {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
        </div>
      </div>
      <div>
        <div
          ref={sidebarRef}
          className={`${
            isMobileMenuOpen ? "block" : "hidden"
          } sm:block fixed sm:sticky sm:top-[80px] left-0 w-[250px] h-full z-[100] transition-transform duration-300 ease-in-out transform ${
            isMobileMenuOpen ? "translate-x-0" : "-translate-x-full"
          } sm:translate-x-0`}
        >
          {/* <div className="flex justify-end p-4 sm:hidden">
            <FaTimes
              className="cursor-pointer text-2xl"
              onClick={() => setIsMobileMenuOpen(false)}
            />
          </div> */}
          <Menu
            onClick={onClickMenuItem}
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["sub1"]}
            mode="inline"
            style={{
              width: !collapsed ? "250px" : "64px",
              minWidth: !collapsed ? "250px" : "64px",
              backgroundColor: "#FBC903",
              border: 0,
              borderTopRightRadius: "24px",
              borderBottomRightRadius: "24px",
              display: "flex",
              flexDirection: "column",
              gap: "0px",
              position: "fixed",
              height: "100vh",
              zIndex: 50,
            }}
            selectedKeys={[selectedKey]}
            inlineCollapsed={collapsed}
          >
            <div
              className={`flex mt-2 justify-start  ${
                collapsed ? "pl-[14px]" : "pl-[20px]"
              }`}
            ></div>

            {items.map((item) => (
              <React.Fragment key={item.key}>
                <div
                  onClick={() => {
                    if (item.childrens) {
                      setKeyCollapseMenu({
                        ...keyCollapseMenu,
                        [item.key]: !keyCollapseMenu[item.key],
                      });
                    } else {
                      navigate(item.path);
                    }
                  }}
                  className={`my-1 flex py-3 cursor-pointer px-6 hover:bg-[#EFBF01] rounded-[6px] hover:border-l-4 hover:border-l-[#101828] hover:border-primary hover:pl-[16px] justify-between items-center ${
                    location.pathname === item.path ||
                    (item.childrens &&
                      item.childrens.some(
                        (childItem) => location.pathname === childItem.path
                      ))
                      ? "bg-[#EFBF01] border-l-4 border-l-[#101828] pl-[16px]"
                      : "text-[#101828] pl-[20px]"
                  }`}
                >
                  <div className="flex gap-3 items-center">
                    {item.icon}
                    {!collapsed && (
                      <div className="text-[16px] font-semibold">
                        {item.label}
                      </div>
                    )}
                  </div>
                  {item.childrens && !collapsed && (
                    <div>
                      <FaCaretDown
                        style={{
                          transform: keyCollapseMenu[item.key]
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                          transition: "all 0.3s ease-in-out",
                        }}
                      />
                    </div>
                  )}
                </div>
                {item.childrens &&
                  keyCollapseMenu[item.key] &&
                  !collapsed &&
                  item.childrens.map((childItem) => (
                    <div
                      key={childItem.key}
                      onClick={() => {
                        navigate(childItem.path);
                      }}
                      className={`my-1 flex py-3 cursor-pointer px-6 hover:bg-bg_light hover:border-l-4 hover:border-primary hover:pl-[16px] justify-between items-center ${
                        location.pathname === childItem.path
                          ? "bg-bg_light border-l-4 border-primary pl-[16px]"
                          : "text-black pl-[20px]"
                      }`}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      <div
                        className="flex gap-2 items-center "
                        style={{ whiteSpace: "nowrap" }}
                      >
                        <div className="text-[16px] ml-[26px]">
                          {childItem.label}
                        </div>
                      </div>
                    </div>
                  ))}
              </React.Fragment>
            ))}
          </Menu>
        </div>
        <div
          className={`grow relative border border-lg  sm:ml-[250px] ${
            location.pathname === "/profile" ? "pt-6" : "sm:p-6 p-[20px]"
          }`}
        >
          {authState && props.children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
