import React from 'react'
import * as Dialog from '@radix-ui/react-dialog';

const Modal = ({
    children,
    open,
    onOpenChange,
}) => {
  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
    <Dialog.Trigger />
    <Dialog.Portal>
      <Dialog.Overlay className='bg-black/50 fixed inset-0 z-[1000] ' />
      <Dialog.Content className='bg-white rounded-md shadow-lg fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[90vw] max-w-[450px] max-h-[100vh] p-6 animate-contentShow z-[9999]'>
        {children}
      </Dialog.Content>
    </Dialog.Portal>
  </Dialog.Root>
  )
}

export default Modal